import React from "react";
import { Link } from "react-router-dom";
import Property from "../../components/Property";

const Contact = () => {
  return (
    <>
      <div className="inner-banner"></div>
      <section className="w3l-breadcrumb">
        <div className="container">
          <ul className="breadcrumbs-custom-path">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li className="active">
              <span className="fa fa-arrow-right mx-2" aria-hidden="true" />
              Contact Us
            </li>
          </ul>
        </div>
      </section>
      <section className="w3l-contact-11 ">
        <div className="contacts-main ">
          <div className="title-content text-center">
            <h6 className="sub-titlehny" />
            <h3 className="hny-title">Get in touch with us</h3>
          </div>
        </div>
        <div className="contant11-top-bg">
          <div className="container">
            <div className="d-grid contact pt-lg-4">
              <div className="contact-info-left d-grid text-left">
                <div className="contact-info mt-4">
                  <h4>Office Address</h4>
                  <p className="mb-3">
                    <label>
                      <i
                        className="fa fa-map-marker"
                        style={{ color: "var(--primary-color)", fontSize: 20 }}
                      />
                    </label>{" "}
                    Guwahati Warehouses 
(A unit of Sidvin Group)
206, 2nd Floor 
Shankar Complex  
Christian Basti 
GS Road 
Guwahati-781005
                  </p>
                </div>
                <div className="contact-info mt-4">
                  <h4>Phone Number</h4>
                  <p className="mb-3">
                    <label>
                      <i
                        className="fa fa-mobile"
                        style={{ color: "var(--primary-color)", fontSize: 20 }}
                      />
                    </label>{" "}
                    Office: +91-7788823888
                  </p>
                </div>
                <div className="contact-info mt-4">
                  <h4>Email Address</h4>
                  <p className="mb-3">
                    <label>
                      <i
                        className="fa fa-envelope-open-o"
                        style={{ color: "var(--primary-color)", fontSize: 20 }}
                      />
                    </label>{" "}
                    <a href="mailto:info@guwahatiwarehouses.com" className="email">
                    info@guwahatiwarehouses.com
                    </a>
                  </p>
                  <p className="mb-3">
                    <label />
                    <a href="#"> http://guwahatiwarehouses.com/</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="w3l-team">
  <div className="team py-5">
    <div className="container py-md-5 py-3">
      <div className="header-section text-center mx-auto">
        <h6 className="sub-titlehny"></h6>
        <h3 className="hny-title">Meet Our Team</h3>
        <p className="my-3"></p>
      </div>
      <div className="row team-row pt-3 mt-5">
        <div className="col-lg-3 col-sm-6 team-wrap">
          <div className="team-info">
            <div className="column position-relative img-circle">
              <img src="assets/images/VikasGoenka-new.jpg" alt className="img-fluid" />
            </div>
            <div className="column-btm">
              <h3 className="name-pos"><a href="#url">Vikas Goenka</a></h3>
              <p>Managing Director </p>
              <div className="social">
                {/* <a href="#facebook" className="facebook"><span className="fa fa-facebook" aria-hidden="true" /></a>
                <a href="#twitter" className="twitter"><span className="fa fa-twitter" aria-hidden="true" /></a> */}
              </div>
            </div>
          </div>
        </div>
        {/* end team member */}
        <div className="col-lg-3 col-sm-6 team-wrap mt-sm-0 pt-sm-0 mt-4 pt-2">
          <div className="team-info">
            <div className="column position-relative img-circle">
              <a href="#url"><img src="assets/images/MadhumitaBarman.jfif" alt className="img-fluid" /></a>
            </div>
            <div className="column-btm">
              <h3 className="name-pos"><a href="#url"> Madhumita Barman</a></h3>
              <p>Department Head</p>
              <div className="social">
                {/* <a href="#facebook" className="facebook"><span className="fa fa-facebook" aria-hidden="true" /></a>
                <a href="#twitter" className="twitter"><span className="fa fa-twitter" aria-hidden="true" /></a> */}
              </div>
            </div>
          </div>
        </div>
        {/* end team member */}
        <div className="col-lg-3 col-sm-6 team-wrap mt-lg-0 pt-lg-0 mt-4 pt-2">
          <div className="team-info">
            <div className="column position-relative img-circle">
              <a href="#url"><img src="assets/images/MrinmoyNandy.jfif" alt className="img-fluid" /></a>
            </div>
            <div className="column-btm">
              <h3 className="name-pos"><a href="#url"> Mrinmoy Nandy</a></h3>
              <p>Business Development Manager</p>
              <div className="social">
                {/* <a href="#facebook" className="facebook"><span className="fa fa-facebook" aria-hidden="true" /></a>
                <a href="#twitter" className="twitter"><span className="fa fa-twitter" aria-hidden="true" /></a> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 team-wrap mt-lg-0 pt-lg-0 mt-4 pt-2">
          <div className="team-info">
            <div className="column position-relative img-circle">
              <a href="#url"><img src="assets/images/HarjeetRajbongshi.jpeg" alt className="img-fluid" /></a>
            </div>
            <div className="column-btm">
              <h3 className="name-pos"><a href="#url">Harjeet Rajbongshi </a></h3>
              <p>Property Acquisition Consultant</p>
              <div className="social">
                {/* <a href="#facebook" className="facebook"><span className="fa fa-facebook" aria-hidden="true" /></a>
                <a href="#twitter" className="twitter"><span className="fa fa-twitter" aria-hidden="true" /></a> */}
              </div>
            </div>
          </div>
        </div>
        {/* end team member */}
      </div>
    </div>
  </div></section>




      <Property/>
    </>
  );
};

export default Contact;
