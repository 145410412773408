import React from 'react'
import { Link } from 'react-router-dom'

const warehousingzone = () => {
  return (
    <>
    <div className="inner-banner"></div>
    <section className="w3l-breadcrumb">
      <div className="container">
        <ul className="breadcrumbs-custom-path">
          <li>
            <Link to="/">Home</Link>
          </li>     
          <li className="active">
            <span className="fa fa-arrow-right mx-2" aria-hidden="true" />
            Warehousing Zone
          </li>
        </ul>
      </div>
    </section>
    <div className="w3l-cwp4-sec py-5 container">
      <div className="container py-md-5 py-3">
        <div className="cwp4-two row align-items-center">
          <div className="cwp4-text ">
            <h6 className="sub-titlehny"></h6>
            <h3 className="hny-title">WAREHOUSING ZONE:</h3>
            <p className="black-color">
            In and around the city of Guwahati, there are many warehousing zones. Each zone comes with some salient features that are unique to that zone. Anyone looking for a warehouse will always have a set of prerequisite conditions that will be aligned with their business.  We have listed the USPs of each zone and you can as a client can get an idea of which zone will be favourable for your business. You can contact us at Guwahati Warehouses if you require any kind of warehouse in any of the warehousing hubs.
            </p>
            <br />
            <h5>Zone 1: Guwahati – NH 37 </h5>
            <br />
            
            <div className='row'>
              <div className='col-md-6 col-sm-12'>
              {/* <p className="black-color">Guwahati city in Assam, India, is a major economic hub and a rapidly growing commercial center. The National Highway 37 which is a 6-lane highway, which extends from Jhalukbari to Khanapara and offers significant scope for warehousing and logistics operations. The availability of land, transportation infrastructure, and the city's strategic location make it an attractive destination for businesses seeking to establish warehousing facilities. Additionally, the government's focus on developing the state's infrastructure and promoting it as an industrial hub has led to increased investment in the region. The presence of the Inland Water Transport terminal and international Airport including further enhances the region's logistics and transportation capabilities. Logistics companies & Tea companies create a huge demand. Guwahati is also is the gateway to all the states in the northeast. This is due the railway connectivity with the rest of the country. The other northeastern state do not have a great railway connectivity owing to the challenging terrain. Thus the major goods are unloaded here in Guwahati for the rest of the states in the northeast as well. Guwahati Warehouses will get you the best warehouses in Guwahati The rent as of March -2023 is 18/- to 24/-INR.
            </p> */}

            <p className="black-color">Guwahati city in Assam, India, is a major economic hub and a rapidly growing commercial center. The city’s 6-lane national highway -37 extends from Jhalukbari to Khanapara and offers significant scope for warehousing and logistics operations. The availability of land, transportation infrastructure, and the city's strategic location make it an attractive destination for businesses seeking to establish warehousing facilities. Additionally, the government's focus on developing the state's infrastructure and promoting it as an industrial hub has led to increased investment in the region. The presence of the Inland Water Transport terminal and international Airport further enhances the region's logistics and transportation capabilities. Logistics companies & Tea companies always have a huge demand. The growth potential of the warehousing sector in Guwahati is immense, and businesses seeking to expand their operations in the region. You can connect with Guwahati Warehouses to take advantage of the changing dynamics to establish themselves in the northeast. The rent as of March -2023 was 18/- to 24/- INR (per sqft, feet per month).
            </p>

              </div>
              <div className='col-md-6 col-sm-12'>
              
              <img
                src="assets/images/GuwahatiNH37.jpeg"
                className="img-fluid"
                alt
              />
          
</div>
            </div>
            
           
            <br />
            
            <br />
            <h5>Zone 2: Pamohi to Rani Gate</h5>
            <br />

            <div className='row'>
              <div className='col-md-6 col-sm-12'>
              <img
                src="assets/images/PamohitoRanigate.jpeg"
                className="img-fluid"
                alt
              />
              </div>
              <div className='col-md-6 col-sm-12'>
              <p className="black-color">
              Pamohi to Rani Gate is a 15.2 km stretch of warehousing zone located on the periphery of the city of Guwahati, Assam. Being closer to the city this is a sought-after location by many companies. Another significant USP of this zone is that it is closer to the railway station and as well as to the international airport. The area is easily accessible by two routes, one from Garchuk NH-37 and the other from Rani gate. This location is perfect for companies looking for storage facilities near Guwahati city for easy and hassle-free product supply. The road leading to the warehouses is 60 feet wide, providing ample space for heavy-duty vehicles. The warehouses available in this area include Grade-A, RCC, multiple-story RCC, and Shed options, making it a versatile location for a variety of companies. Several notable companies like Voltas Limited, Delhivery Hub, Big Basket, Skipper Limited, DHL Supply Chain India, Varun Beverage, Colgate, Jio, IFB Industries LTD, Pro Connect Supply Chain, Myntra, Ethics Innovations, Aries Agro Limited, Godrej, DTDC, Pidilite, Adani Wilmar Limited, Abbott Healthcare Pvt, Loadshare Network Pvt, and many more have already established their presence in this area. The location's strategic importance, combined with its top-notch facilities, makes Pamohi to Rani Gate an ideal location for businesses looking for warehousing solutions near Guwahati. The rent as of March 2023 was - 17/- to 24/- INR (per sqft, per month).
            </p>
</div>
            </div>
           
            <br />
          
            <h5>Zone 3:  North Guwahati</h5>
            <br />
            <div className='row'>
              <div className='col-md-6 col-sm-12'>
              <p className="black-color">
              Bramhaputra Industrial Park, Gauripur, Chowkigate, Changsari, and Madanpur areas are prime warehousing and industrial zones in North Guwahati. This zone has become a preferred choice for warehousing and industrial purposes due to its proximity to Guwahati city. This area also boasts of great connectivity. There are also huge land parcels that are available for sale and lease in North Guwahati and that makes this area very attractive.  These areas have a strategic location advantage as they are well-connected to Guwahati city through an existing bridge. There are an additional 6-lane highway and a new bridge coming up that will further improve the connectivity and reduce travel time. Apart from that there are 2 major railway stations in Changsari and Athyaguri that will be a huge advantage to companies. North Guwahati to Madanpur stretch of 19.5 km has emerged as a prominent warehousing zone, starting from the iconic Saraighat Bridge and extending up to Madanpur. With the upcoming infrastructure projects, the area is expected to witness rapid growth in the near future. The government's efforts to promote this region as a warehousing and industrial hub are expected to attract investments and generate employment opportunities Major companies like Reliance, Tata, Dalmia Cement, Nestle, Emami, Marico, Parle-G and many more. The rent as of March was 2023 15/- to 25/-INR (per sqft per month).
            </p>
              </div>
              <div className='col-md-6 col-sm-12'>
              <img
                src="assets/images/NorthGuwahati.jpeg"
                className="img-fluid"
                alt
              />
</div>
            </div>
           
            <br />
           
            <br />
            <h5>Zone 4: Baihata Chariali to Nalbari</h5>
            <br></br>

            <div className='row'>
              <div className='col-md-6 col-sm-12'>
              <img
                src="assets/images/BaihatatoNalbari1.jpeg"
                className="img-fluid"
                alt
              />
              </div>
              <div className='col-md-6 col-sm-12'>
              <p className="black-color">
              Just 32 km away from Guwahati, the Baihata Chariali stretch to Nalbari is a 35.6 km stretch that offers ample warehousing opportunities. This area is located along National Highway 27 and is well-connected to major transportation routes.  The availability of land and infrastructure, combined with the government's efforts to promote this stretch as a warehousing and logistics corridor, has led to the emergence of numerous warehousing facilities in the region. The close proximity of this region to many satellite towns makes it a perfect location. Some of the nearby towns are Rangia, Nalbari, Goreswar, Kamalpur, Tamulpur, Bezera, Sipajar, Barama, Pathsala, and many more. Many e-commerce companies trying to capture the market can set up their warehouses here. The government of Assam is taking special interest in this area and will be coming up with schemes and infrastructure that will benefit both the land owners and investors. The rent as of March was - 2023 is 15/- to 20/-INR (per sqft per month)
            </p>
</div>
            </div>
           
            <br></br>
            <br />
            <h5>Zone 5: Dharapur to Kukurmara</h5>
            <br></br>
            <div className='row'>
              <div className='col-md-6 col-sm-12'>
              <p className="black-color">
              The warehousing scope from Dharapur to Kukurmara is vast and expanding rapidly, offering an array of opportunities for businesses in the area. Dharapur and Bijaynagar are located in close proximity to Guwahati airport, making them ideal locations for the storage and distribution of goods. In addition, the Rampur Industrial Park and Kukurmara provide an excellent platform for businesses to expand and grow. With their strategic location, these areas have a wide scope to capture nearby regions such as Chhaygaon, Boko, Dhupdhara, Dudhnoi, and Goalpara.  With the increasing demand for storage and logistics solutions, the warehousing scope from Dharapur to Kukurmara provides a plethora of opportunities for businesses looking to expand their operations. The rent as of March-2023 is 16/- to 20/- INR. (per sqft per month)
            </p>
              </div>
              <div className='col-md-6 col-sm-12'>
              <img
                src="assets/images/DharaourtoKukurnara.jpeg"
                className="img-fluid"
                alt
              />
</div>
            </div>
           
            <br></br>

            <br />
            <h5>Zone 6: Jorabat-Sonapur- Khetri</h5>
            <br></br>
            <div className='row'>
              <div className='col-md-6 col-sm-12'>
              <p className="black-color">
              Jorabat is located just a few kilometers away from Guwahati city towards Meghalaya.  The warehousing scope in Jorabat gives easy access to the nearby places of Meghalaya and Guwahati city.  The Sonapur to Khetri is about a 13 km stretch and is coming up in a big way. Many industries and companies are looking for spaces to set up their base there.  Although this area has a lot of significance with many bricks and cement factories being there for many years now.  There are many upcoming warehouses in that area.  The rent in that area as of March-2023 is 16/- to 21/- INR. (per sqft per month)
            </p>
              </div>
              <div className='col-md-6 col-sm-12'>
              <img
                src="assets/images/JorabaSonapurKhetri.jpeg"
                className="img-fluid"
                alt
              />
</div>
            </div>
          
            <br></br>
          </div>
         
        </div>
      </div>
    </div>
   
  </>
  )
}

export default warehousingzone