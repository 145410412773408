import React from "react";
import AboutUs from "../components/AboutUs";
import LookingFor from "../components/LookingFor";
import MainSlider from "../components/MainSlider";
import Property from "../components/Property";
import Records from "../components/Records";
import Testimonial from "../components/Testimonial";

const Home = () => {
  return (
    <div>
      <MainSlider />
      <LookingFor />
      <AboutUs />
      <Records />
      <Testimonial />
      <Property />
    </div>
  );
};

export default Home;
