import React from "react";
import { Link } from "react-router-dom";

const Privacy = () => {
  return (
    <>
      <div className="inner-banner"></div>
      <section className="w3l-breadcrumb">
        <div className="container">
          <ul className="breadcrumbs-custom-path">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li className="active">
              <span className="fa fa-arrow-right mx-2" aria-hidden="true" />{" "}
              PRIVACY POLICY
            </li>
          </ul>
        </div>
      </section>

 <div className="w3l-cwp4-sec py-5">
  <div className="container py-md-5 py-3">
    <div className="cwp4-two row align-items-center">
      <div className="cwp4-text col-lg-12">
        <h6 className="sub-titlehny" />
        <h3 className="hny-title">PRIVACY POLICY</h3>
        <p className="black-color">
          Guwahati Warehouses consider the privacy and protection of our
          clients' data, both corporate and personal, to be of the utmost
          importance and we take robust measures across our business to
          protect the security and integrity of all such information.
        </p>
        <p className="black-color">
          However, the law requires us to take particular steps in
          relation to the processing and protection of personal data. To
          ensure that you feel confident about providing us with your
          personal information when communicating with us and using our
          services, we outline below our practices in relation to the
          collection and use of personal data. This policy (together with
          our terms of use and any other documents referred to on it) sets
          out the basis on which we will collect, use and process personal
          data. Please read this Privacy Policy carefully to ensure you
          understand how we handle your personal data; please also check
          this page from time to time as the Policy may be updated.
        </p>
        <br />
        <h6>INFORMATION WE MAY COLLECT FROM YOU</h6>
        <br />
        <p className="black-color">
          We may collect and process the following information about you:
        </p>
        <ul className="cont-4 mt-lg-5 mt-4">
          <li>
            <span className="fa fa-check-circle-o" />information you
            provide by filling in forms on our website and information
            given in hard copy form or by any other means providing: your
            name; date of birth; address; interests; marketing
            preferences; when appropriate your credit information; and any
            other personal information relevant to the services we
            provide. This information may be provided when you are
            registering interest, transacting with us, subscribing to our
            services or for newsletters or requesting information;
          </li>
          <li>
            <span className="fa fa-check-circle-o" />correspondence or
            other contacts you may have with us;
          </li>
          <li>
            <span className="fa fa-check-circle-o" />details of
            transactions you carry out through our website, and any
            communications by telephone, in person or by any other means
            relating to those transactions. This may include recording
            certain telephone conversations, for example relating to live
            bidding;
          </li>
          <li>
            <span className="fa fa-check-circle-o" />details of your
            visits to our website including, but not limited to, traffic
            data, web logs or other communication data and the resources
            you access.
          </li>
        </ul>
      </div>
      <div className="row mt-2 cwp4-text" />
    </div>
  </div>
</div>

    </>
  );
};

export default Privacy;
