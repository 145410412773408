import React from 'react';
import { Link } from 'react-router-dom';


const MainSlider = () => {
  return (
    <>

      <section class="w3l-main-slider" id="home">
        <div class="companies20-content">
          <div class="">


            <div class="item">
              <li>
                <div class="slider-info banner-view banner-top2 bg bg2">
                  <div class="banner-info">
                    <div class="container pt-5">
                      {/* <h3>With 12 + Year Of Experience</h3> */}

                      <div class="banner-info-bg about">
                        <h5 className='' style={{fontSize:"34px",fontWeight:"700",lineHeight:"95px",color:"#2e2e6b",textShadow:"none"}}>BEST WAREHOUSE & INDUSTRIAL LAND OPTIONS IN GUWAHATI</h5>

                      {/* <h5>READY / BTS WAREHOUSE OPTION</h5> */}

                      <div className='btn'>
                        <Link class="btn btn-style btn-primary mt-sm-5 mt-4 mr-2 " to="/about"> About Us</Link>
                        <Link class="btn btn-style btn-white mt-sm-5 mt-4" to="/contact"> Contact Us</Link>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </li>
        </div>
        {/* <div class="item">
          <li>
            <div class="slider-info banner-view banner-top3 bg bg2">
              <div class="banner-info">
                <div class="container">
                  <div class="banner-info-bg">
                    <h5>Provide Effective Building Solutions</h5>
                    <p class="mt-4 pr-lg-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit
                      quisquam, doloremque placeat aut numquam ipsam. </p>
                    <a class="btn btn-style btn-primary mt-sm-5 mt-4 mr-2" href="about.aspx"> About Us</a>
                    <a class="btn btn-style btn-white mt-sm-5 mt-4" href="contact.aspx"> Contact Us</a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </div> */}
      </div>
      <div class="arrow-downhny">
        <a href="#about" class="arrow-down text-center position-absolute">
          <span class="arrow-down-icon">
            <span class="fa fa-arrow-down"></span>
          </span>
        </a>
      </div>
    </div>
  </section >
    </>
  )
}

export default MainSlider