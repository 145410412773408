import React from 'react'

const Testimonial = () => {
  return (
    <>
 <section className="w3l-testimonials py-5" id="testimonials">
  {/* main-slider */}
  <div className="container py-md-4 mb-md-0 mb-md-5 mb-4">
    <div className="heading text-center mx-auto">
      <h3 className="hny-title text-center mb-5">Our Testimonial </h3>
    </div>
    <div className="owl-testimonial owl-carousel owl-theme">
      <div className="item">
        <div className="slider-info">
          <div className="img-circle">
            <img src="assets/images/testi-def.png" className="img-fluid rounded" alt="client image" />
          </div>
          <div className="message-info">
            <span className="fa fa-quote-left mr-2" />
            <div className="message"> It is rare that you come across a real-estate professional who has a combination of expertise, skill and professional acumen of time needed to buy/sell/rent your property. Guwahati Warehouses's team is thorough professional from inspecting your property to arrange visits and to help in closing the deal with their multiple clients. I would highly recommend them.</div>
            <div className="name">- Rahul Jain</div>
            <div className="desp" />
          </div>
        </div>
      </div>
      <div className="item">
        <div className="slider-info">
          <div className="img-circle">
            <img src="assets/images/testi-def.png" className="img-fluid rounded" alt="client image" />
          </div>
          <div className="message-info">
            <span className="fa fa-quote-left mr-2" />
            <div className="message">Guwahati Warehouses met all my real-estate needs in a professional, responsible &amp; efficient manner. They had a very patient attitude ensuring that I would be 100% happy with the purchase of my granary. I have referred them to my multiple friends &amp; family because I knew they would be satisfied with the excellent service they provide </div>
            <div className="name">- Sahil Ali</div>
            <div className="desp" />
          </div>
        </div>
      </div>
      <div className="item">
        <div className="slider-info">
          <div className="img-circle">
            <img src="assets/images/testi-def.png" className="img-fluid rounded" alt="client image" />
          </div>
          <div className="message-info">
            <span className="fa fa-quote-left mr-2" />
            <div className="message">We bought a warehouse of around 20,000 sq,ft at Guwahati through Guwahati Warehouses for our self use. Later we were forced by the circumstances to rent it a year later. Buying the warehouse was a great experience - Guwahati Warehouses heard us in terms of what we exactly required. Once they took us through the warehouse, we knew it was perfect for us. Guwahati Warehouses used their expertise &amp; connections to get it rented in just 30 days. During this period they were dedicated &amp; supportive. We couldn't have done it without them.</div>
            <div className="name">- Monmayuri Bora</div>
            <div className="desp" />
          </div>
        </div>
      </div>
      <div className="item">
        <div className="slider-info">
          <div className="img-circle">
            <img src="assets/images/testi-def.png" className="img-fluid rounded" alt="client image" />
          </div>
          <div className="message-info">
            <span className="fa fa-quote-left mr-2" />
            <div className="message"> From the night of sale to the handling of visitations and to recommendations of other professionals to work with, Vikas &amp; his colleagues have been amazing. Even post closing Vikas has delivered with suggestions &amp; recommendations on contracts &amp; etc.,. Super impressed indeed ! </div>
            <div className="name">- Jayanta Baruah</div>
            <div className="desp" />
          </div>
        </div>
      </div>
      <div className="item">
        <div className="slider-info">
          <div className="img-circle">
            <img src="assets/images/testi-def.png" className="img-fluid rounded" alt="client image" />
          </div>
          <div className="message-info">
            <span className="fa fa-quote-left mr-2" />
            <div className="message"> I was very pleased with the response &amp; service I received from Guwahati Warehouses. Vikas came out &amp; did a thorough inspection &amp; gave a great quote. He then spent time walking me through the quote &amp; answered all my querries. He is very patient, knowledgable &amp; trustworthy and did a wonderful job. It is nice to be treated well from a company that clearly cares about their customers &amp; their investments. Thank you</div>
            <div className="name">- Viren Agarwal</div>
            <div className="desp" />
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* /main-slider */}
</section>

    </>
  )
}

export default Testimonial