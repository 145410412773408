import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";

const Header = () => {
  const [toggle, setToggle] = useState(0);
  const show = () => {
    setToggle(1);
    document.getElementById("toggleBtn").style.display = "flex";
  };
  const hide = () => {
    setToggle(0);
    document.getElementById("toggleBtn").style.display = "none";
  };

  return (
    <>
      <header id="site-header" className="fixed-top nav-fixed seconddesign">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-dark stroke logodesign ">
            <Link className="navbar-brand" to="/">
              <img
                src="/assets/images/Guwahati_Warehouses.png"
                alt="Your logo"
                title="Your logo"
                // style={{ height: 54 }}
              />
            </Link>
            <h1>
              <Link className="navbar-brand" to="/">
              <span className="sub-log">Guwahati Warehouses</span>
               {/* <span style={{fontSize:10,textAlign:"end"}}>-360 Warehouse Solutions</span> */}
              </Link>
            </h1>
            {/* desktop */}
            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav mx-lg-auto">
                <li className="nav-item ">
                  <Link className="nav-link" to="/">
                    Home <span className="sr-only">(current)</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about">
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/services">
                    Services
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/warehousingzone">
                    Warehousing Zones
                  </Link>
                </li>
                 <li className="nav-item">
                  <Link className="nav-link" to="/blogs">
                   Blogs
                  </Link>
                </li>
                 <li className="nav-item">
                  <Link className="nav-link" to="/ciril">
                   CIRIL
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link " to="/contact">
                    Contact
                  </Link>
                </li>
                <li className="top-quote ml-lg-4 mt-lg-0 mt-3">
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSfOmKbYY8pFC-EAkpRuxGNl2JXIIDZDqAf2yVZzS-6jTYkPiQ/viewform?usp=sf_link"
                    className="btn btn-style btn-primary btn-header" 
                  >
                    Submit Property
                  </a>
                </li>
              </ul>
            </div>
            {/* desktop */}

            <button
              className="navbar-toggler  collapsed bg-gradient"
              type="button"
              data-toggle="collapse"
              data-target="#toggleBtn"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {toggle === 0 ? (
                <div onClick={show}>
                  <button className="btnMenu">
                    <GiHamburgerMenu />
                  </button>
                </div>
              ) : (
                <div onClick={hide}>
                  <button className="btnMenu">
                    <AiOutlineClose />
                  </button>
                </div>
              )}
            </button>

            <div
              className="collapse navbar-collapse "
              id="toggleBtn"
              style={{ display: "none" }}

            >
              <ul className="navbar-nav mx-lg-auto">
                <li className="nav-item active">
                  <Link className="nav-link" to="/" onClick={hide}>
                    Home <span className="sr-only">(current)</span>
                  </Link>
                </li>
                <li className="nav-item" onClick={hide}>
                  <Link className="nav-link" to="/about">
                    About
                  </Link>
                </li>
                <li className="nav-item" onClick={hide}>
                  <Link className="nav-link" to="/services">
                    Services
                  </Link>
                </li>

                <li className="nav-item" onClick={hide}>
                  <Link className="nav-link" to="/warehousingzone">
                  Warehousing Zones
                  </Link>
                </li>

                <li className="nav-item" onClick={hide}>
                  <Link className="nav-link" to="/blogs">
                  Blogs
                  </Link>
                </li>

                <li className="nav-item" onClick={hide}>
                  <Link className="nav-link" to="/ciril">
                  CIRIL
                  </Link>
                </li>
                <li className="nav-item" onClick={hide}>
                  <Link className="nav-link" to="/contact">
                    Contact
                  </Link>
                </li>
                <li className="top-quote ml-lg-4 mt-lg-0 mt-3">
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSfOmKbYY8pFC-EAkpRuxGNl2JXIIDZDqAf2yVZzS-6jTYkPiQ/viewform?usp=sf_link"
                    className="btn btn-style btn-primary"
                  >
                    Submit Property
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;

