import { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import MainLayout from "./components/layout/MainLayout";
import About from "./pages/about/About";
import Blogs from "./pages/blogs/Blogs";
import Ciril from "./pages/ciril/Ciril";
import Contact from "./pages/contact/Contact";
import Cookies from "./pages/cookies/Cookies";
import Demo from "./pages/Demo";

import Home from "./pages/Home";
import Privacy from "./pages/privacy/Privacy";
import Services from "./pages/services/Services";
import TermsAndConditions from "./pages/termandconditions/TermsAndConditions";
import Warehousingzone from "./pages/warehousingzone/Warehousingzone";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  return (
    <>
      <BrowserRouter>
      <ScrollToTop/>
        <Routes>
          <Route path="/" exact element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/termcondition" element={<TermsAndConditions />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/cookies" element={<Cookies />} />
            <Route path="/warehousingzone" element={<Warehousingzone/>} />
            <Route path="/blogs" element={<Blogs/>} />
            <Route path="/ciril" element={<Ciril/>} />
            <Route path="/demo" element={<Demo/>} />
           

          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
