import React from 'react'

const Records = () => {
  return (
    <>
<section className="w3l-stats py-lg-5 py-5" id="stats">
  <div className="gallery-inner container py-md-5 py-3">
    <div className="row stats-con">
      <div className="col-lg-3 col-6 stats_info counter_grid">
        <span className="fa fa-laptop" />
        <p className="counter">120</p>
        <h4>Projects &amp; Residentials Completed in 2020</h4>
      </div>
      <div className="col-lg-3 col-6 stats_info counter_grid1">
        <span className="fa fa-users" />
        <p className="counter">80</p>
        <h4>Qualified Employees &amp; Workers With Us</h4>
      </div>
      <div className="col-lg-3 col-6 stats_info counter_grid mt-lg-0 mt-5">
        <span className="fa fa-trophy" />
        <p className="counter">800+</p>
        <h4>Satisfied Clients We Served Globally</h4>
      </div>
      <div className="col-lg-3 col-6 stats_info counter_grid2 mt-lg-0 mt-5">
        <span className="fa fa-smile-o" />
        <p className="counter">20</p>
        <h4>Years Of Experience In The Industry</h4>
      </div>
    </div>
  </div>
</section>

    </>
  )
}

export default Records