import React from "react";
import { Link } from "react-router-dom";
import Property from "../../components/Property";

const Services = () => {
  return (
    <>
      <div className="inner-banner"></div>
      <section className="w3l-breadcrumb">
        <div className="container">
          <ul className="breadcrumbs-custom-path">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li className="active">
              <span className="fa fa-arrow-right mx-2" aria-hidden="true" />
              Services
            </li>
          </ul>
        </div>
      </section>
      <div className="w3l-cwp4-sec py-5 container">
        <div className="container py-md-5 py-3">
          <div className="cwp4-two row align-items-center">
            <div className="cwp4-text ">
              <h6 className="sub-titlehny">Services</h6>
              <h3 className="hny-title">WAREHOUSING SOLUTIONS:</h3>
              <p className="black-color">
              Working with Guwahati Warehouses gives you the opportunity to get access to the best warehouses in Guwahati. We provide all types of warehouse spaces in Guwahati and in the northeast.
              </p>
              <br />
            <h5 style={{textShadow: '2px 2px #ffd0bb'}}>Ready Warehouse: </h5>

              <br />
              <p className="black-color">
              Ready warehouses are a good option for tenants who need warehouse space quickly A ready warehouse is a warehouse that is completed and ready for occupancy. It is a fully functional warehouse that has been built and is available for lease or purchase. The warehouse is equipped with all the necessary features and infrastructure such as loading docks, clear height, electrical and plumbing systems, fire safety systems, and other necessary amenities. The warehouse is ready for immediate use and does not require any additional construction or renovation work. Our listing of ready warehouses in Guwahati is cost-effective and very well well-maintained.
               
              </p>
              <br />
              {/* <p className="black-color">
                The warehouse is ready for immediate use and does not require
                any additional construction or renovation work. Our listing of
                ready warehouses in Guwahati is cost-effective and very well-maintained.
              </p> */}
              {/* <br /> */}
              {/* <p className="black-color">
                Ready warehouses are a good option for tenants who need
                warehouse space quickly and do not have time to wait for a new
                warehouse to be built or for an existing warehouse to be
                renovated. They can move in immediately and start their
                operations without any delays. Additionally, ready warehouses
                are also a good option for tenants who are looking for a
                warehouse in a specific location and are unable to find an
                existing one. They can also be a cost-effective option as
                compared to built-to-suit warehouses, since the tenant does not
                have to pay for the design and construction costs.
              </p> */}
              {/* <br />
              <p className="black-color">
                However, tenants should be aware that ready warehouses may not
                be fully customized to their specific needs and may require some
                renovation or remodelling work to meet their requirements.
                Additionally, tenants should also consider the long-term
                viability of the location, the condition of the warehouse, and
                the terms of the lease or purchase agreement before committing
                to a ready warehouse.
              </p> */}
              <br />
              <h5 style={{textShadow: '2px 2px #ffd0bb'}}>Under-Construction Warehouse:</h5>
              <br />
              <p className="black-color">
               An under-construction warehouse is a warehouse that is currently in the process of being built.  The construction of the warehouse may be in various stages, from the initial foundation work to the final completion
              </p>
              <br />
              <p className="black-color">
              An under-construction warehouse can be a good option for tenants who are looking for a new warehouse space but are unable to find an existing one that meets their needs. These warehouses give tenants the flexibility to modify the warehouses to a certain extent as per their requirements. They can be made ready for occupancy within a shorter time frame as compared to build-to-suit warehouses. As in these properties, the basic framework is already done and the rest of the fabrication can be done according to the client’s client requirements and needs. Guwahati Warehouses can help you source the best warehouses in Guwahati.
              </p>
              <br />
              {/* <p className="black-color">
                However, the tenant must be aware that the construction process
                may take some time and may also have some delays and unexpected
                costs. Additionally, there may be disruptions to the tenant's
                operations during the construction period, and it's necessary to
                have a detailed agreement with the developer regarding the
                completion date and the lease terms.
              </p>
              <br />
              <p className="black-color">
                Moreover, tenants may have to pay advance rent and deposit to
                the developer, which may or may not be refundable in case of
                delays or if the tenant decides not to move in. Tenants should
                also consider the risks associated with investing in an
                under-construction warehouse, such as the developer's
                reputation, the quality of the construction, and the long-term
                viability of the location.
              </p> */}
              {/* <br /> */}
              <h5 style={{textShadow: '2px 2px #ffd0bb'}}>Built-to-Suit Warehouses:</h5>
              <br />
              <p className="black-color">
              A built-to-suit warehouse is a custom-made warehouse where the developer builds the warehouse according to the tenant's requirements and specifications. In these cases, the tenant will share a requirement in terms of size, layout, location, loading docks, clear height, and other special features. A BTS warehouse is best suited when a client is unable to get a property that meets their requirement. It is also imperative that they have a time buffer for the property to get ready. The biggest advantage of a built-to-suit warehouse is that it allows the tenant to have a warehouse that is tailored to their specific needs, which can help them to operate more efficiently and effectively.
              </p>
              <br />
              <p className="black-color">
              In a built-to-suit warehouse, the tenant typically signs a long-term lease agreement with the developer, Once the warehouse is completed, the tenant takes possession and begins using the space. Our team at Guwahati Warehouses will help you get the best solution in BTS properties.
              </p>
              <br />
              
              <br />
              {/* <p className="black-color">
                However, the disadvantage of a built-to-suit warehouse is that
                it takes time to build, and the tenant may have to wait for some
                time before moving into the space. Additionally, the tenant may
                have to pay more for a built-to-suit warehouse than for an
                existing warehouse, due to the costs of design and construction.
              </p> */}
            </div>
            <div className=" cwp4-text">
              <br />
              <h3>INDUSTRIAL LAND ACQUISITION:</h3>
              <br />
              <p className="black-color">
              Industrial land acquisition has a huge potential in Guwahati. The focus of the government and the availability of huge land parcels around Guwahati makes it a perfect location for land acquisition. 
              </p>
              <ul className="cont-4 mt-lg-5 mt-4">
                <li>
                  <span className="fa fa-check-circle-o" /> 
                  Identifying potential sites: We assist in identifying potential industrial land sites that match the client's specific requirements and needs. This may include researching and compiling a list of available industrial land parcels that are perfect for the client's intended use.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" />
                  Conducting due diligence: We help conduct due diligence on potential industrial land sites. This includes reviewing zoning and land use regulations, researching the property's ownership history, and assessing the property's environmental and physical condition.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" />
                  Negotiating the purchase: We help negotiate the purchase of the industrial land on behalf of the client. This may include helping the client develop and present an offer, and negotiating the purchase price and terms.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" />
                  Permitting and zoning: We can help the client understand the requirements and regulations of the local government that needs to be followed, and assist in preparing the necessary documentation to obtain the necessary permits and approvals.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" />
                  Closing the deal: We help the client close the industrial land acquisition deal. This may include assisting with the transfer of ownership and the transfer of funds.
                </li>
              </ul>
              <p className="black-color">
              Overall, Guwahati Warehouses play a crucial role in helping you navigate the complex process of industrial land acquisition and help to are a suitable industrial land parcel that meets your needs and goals.
              </p>
            </div>
            <div className=" cwp4-text">
              <br />
              <h3>INDUSTRIAL LAND DISPOSAL:</h3>
              <br />
              <p className="black-color">
              Guwahati Warehouses assists you in industrial land disposal. We help identify, evaluate, and secure potential buyers for your industrial land. Our services include.
              </p>
              <ul className="cont-4 mt-lg-5 mt-4">

              <li>
                  <span className="fa fa-check-circle-o" />
                  Finding the right buyer: Getting and evaluating the right buyer is the most crucial part of Industrial land disposal.  We help clients find the right buyer for their property by identifying potential buyers who are looking for industrial land that meets their specific requirements and needs.
                </li>

                <li>
                  <span className="fa fa-check-circle-o" />
                  Assessing the property: We assess the property's 
                  value and potential marketability. This will include 
                  conducting a market analysis, researching comparable 
                  sales, and providing an opinion of the value of the property.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" />
                  Marketing the property: We help market the property to potential buyers. This may include creating marketing materials, listing the property on relevant websites, and reaching out to potential buyers directly.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" />
                  Showing the property: We help show the property to potential buyers. This will include scheduling tours of the property, providing information about the property, and answering any questions buyers may have.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" />
                  Negotiating the sale: We help negotiate the sale of the industrial land on behalf of the client. This may include helping the client develop and present an offer, negotiating the sale price and terms, and helping to secure financing for the purchase.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" />
                  Closing the deal: We help you close the industrial land disposal deal. This may include assisting with the transfer of ownership and the transfer of funds.
                </li>
                
              </ul>
              <p className="black-color">
              Guwahati Warehouse is the best choice for your industrial land disposal here in Guwahati and its surrounding.
              </p>
            </div>

            <div className=" cwp4-text">
              <br />
              <h3>INDUSTRIAL SHED OPTIONS </h3>
              <br />
              <p className="black-color">
              An Industrial shed can be of varied utility and is a very significant part of logistic parks. An industrial shed can be versatile and can be customized and designed as per the client’s requirements. There are multiple kinds of industrial sheds depending on the use. The ultimate end-use defines the construction and execution of the warehouse. A steel warehouse will be more suitable for an automotive whereas an agricultural shed is more suitable for livestock etc. Guwahati Warehouses helps you procure the best industrial sheds in Guwahati.
              </p>
              <br/>Working with us will give you the following services & solutions:
              <ul className="cont-4 mt-lg-5 mt-4">

              <li>
                  <span className="fa fa-check-circle-o" />
                  Site selection: Identifying and evaluating the ideal industrial shed in Guwahati.
                </li>

                <li>
                  <span className="fa fa-check-circle-o" />
                  Lease negotiation: Assisting the client in negotiating all the terms and conditions of the lease.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" />
                  Market research: Providing the client with market data, including rental rates and availability for comparable properties in the area.

                </li>
                <li>
                  <span className="fa fa-check-circle-o" />
                  Legal documentation: Reviewing and preparing legal documents such as the lease agreement, ensuring that they comply with local laws and regulations.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" />
                  Budgeting and forecasting: Assessing the client's financial needs and helping them plan for future expansion or downsizing.
                </li>
              </ul>
            </div>


            <div className=" cwp4-text">
              <br />
              <h3>INVESTMENT OPPORTUNITIES WITH GUWAHATI WAREHOUSES:</h3>
              <br />
              <p className="black-color">
              Guwahati Warehouses assists in identifying and evaluating investment opportunities by providing a wide range of services to help you identify and evaluate potential real estate investments. Some of the ways we help in identifying and evaluating investment opportunities include.
              </p>
              <ul className="cont-4 mt-lg-5 mt-4">
                <li>
                  <span className="fa fa-check-circle-o" /> Market analysis: We help clients understand the current market conditions and trends. This may include researching comparable sales, identifying areas of growth, and providing an opinion of value for potential investments.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" /> Investment analysis:
                  We help clients evaluate potential investments by analysing the property's cash flow, return on investment, and risk.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" />
                  Deal structuring: We help clients structure deals to minimize risk and maximize returns. This may include identifying potential partners and investors, negotiating terms, and helping to secure financing.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" />
                  Due diligence: We help clients conduct due diligence on potential investments. This may include reviewing zoning and land use regulations, researching the property's ownership history, and assessing the property's environmental and physical condition.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" /> Closing the deal:We help our clients, close investment deals. This may include assisting with the transfer of ownership and the transfer of funds.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" /> Portfolio
                  management: We help clients manage their real estate portfolio by providing ongoing market analysis and investment advice, helping clients make informed decisions about when to buy, hold, or sell properties.
                </li>
              </ul>
              <p className="black-color">
              Overall, Guwahati Warehouses play a crucial role in helping you identify and evaluate potential real estate investments by providing valuable market insights, conducting due diligence, and helping to structure and close deals. We also provide ongoing support and advice to help clients maximize the returns on their investments.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Property />
    </>
  );
};

export default Services;
