import React from "react";
import { Link } from "react-router-dom";
import Property from "../../components/Property";

const About = () => {
  return (
    <>
      <div className="inner-banner"></div>
      <section className="w3l-breadcrumb">
        <div className="container">
          <ul className="breadcrumbs-custom-path">
            <li>
              <Link to="/">Home</Link>
              {/* <a href="#url">Home</a> */}
            </li>
            <li className="active">
              <span className="fa fa-arrow-right mx-2" aria-hidden="true" />{" "}
              About Us
            </li>
          </ul>
        </div>
      </section>

      <div className="w3l-cwp4-sec py-5">
        <div className="container py-md-5 py-3">
          <div className="cwp4-two row align-items-center">
            <div className="cwp4-image col-lg-6 pr-lg-5 mb-lg-0 mb-5">
              <img src="assets/images/abc.PNG" className="img-fluid" alt />
            </div>
            <div className="cwp4-text col-lg-6 aboutpage">
              <h6 className="sub-titlehny">About Us</h6>
              <h3 className="hny-title">Welcome to Guwahati Warehouses</h3>
              <p className="black-color">
                Guwahati Warehouses is a unit of “Sidvin Group”, an organization
                led by Mr. Vikas Goenka, a dynamic realtor and an entrepreneur
                with more than a decade of experience in the real estate
                industry.
              </p>
              <p className="black-color">
                Guwahati, being the largest city in the North-East region of
                India, is an important center for trade and commerce. It is well
                connected by road, rail, and air, and has an international
                airport. This makes it a strategic location for warehousing and
                logistics operations. The close proximity to major cities is the
                reason why Guwahati is the best warehousing location. The
                Guwahati Tea Auction Centre, the largest tea auction center in
                the world, makes this city an important location for the tea
                industry.
              </p>
            </div>
            <div className="container mt-2 cwp4-text  py-md-5 py-3">
              <p className="black-color">
                Guwahati Warehouses is dedicated to providing expert guidance
                and top-notch service to companies in need of warehouses,
                industrial sheds, and lands for leasing/buying in Guwahati and
                in the northeast. Our team of experienced consultants thoroughly
                understands the warehousing and logistics industry and can give
                valuable input that will make decision-making easier.
              </p>
              <p className="black-color">
                Years of experience in the industry and a huge warehouse
                inventory, give us the ability to identify properties that are a
                perfect fit for our clients in every aspect. Whether you are a
                small start-up or a large corporation, we work closely with you
                to understand your unique requirements and help you find the
                best warehouse in Guwahati.
              </p>
              <p className="black-color">
                We understand that finding the best warehouse space can be a
                daunting task, which is why we strive to make the process as
                smooth and stress-free as possible. We take pride in ourselves
                for our commitment to our clients and our ability to build
                long-lasting relationships. Our goal is to help businesses
                thrive by finding the ideal warehouse space in Guwahati.
              </p>
              <ul className="cont-4 mt-lg-5 mt-4">
                <li>
                  <span className="fa fa-check-circle-o" /> Site selection:
                  Identifying and evaluating ideal warehouse locations in Guwahati.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" /> Lease negotiation:
                  Assisting the client in negotiating all the terms and conditions of the lease.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" /> Market research: Providing the client with market data, including rental rates and availability for comparable properties in the area.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" />
                  Legal documentation: Reviewing and preparing legal documents such as the lease agreement, ensuring that they comply with local laws and regulations.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" />
                  Budgeting and forecasting: Assessing the client's financial needs and helping them plan for future expansion or downsizing.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" />
                  Move-in coordination: Assisting the clients moving into the new warehouse, including coordinating with landlords, contractors, and other vendors.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" />
                  Ongoing management: Providing ongoing support and management services, such as renewing leases, negotiating rent increases, and handling any issues that may arise.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" />
                  Exit strategies: Helping the client plan for the eventual end of the lease and finding a new warehouse space if needed.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" />
                  Client representation: Representing the tenant's interest and negotiating on their behalf with the landlord & Vice versa.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" />
                  Supply Chain Analysis: Assessing the client's supply chain and logistics needs and recommending warehouse solutions that will optimize efficiency and reduce costs.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Property />
    </>
  );
};

export default About;
