import React from 'react'

const Property = () => {
  return (
    <>
 <section className="w3l-bottom py-5">
  <div className="container py-md-4 py-3 text-center">
    <div className="header-section text-center mx-auto">
      <h3 className="hny-title">Looking To Buy, Sell Or Rent Your Property in Guwahati
</h3>
      <p className="my-3 px-lg-5"> </p>
    </div>
    <div className="buttons mt-5">
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSfOmKbYY8pFC-EAkpRuxGNl2JXIIDZDqAf2yVZzS-6jTYkPiQ/viewform?usp=sf_link" className="btn btn-style btn-secondary ml-2">Submit Now</a>
    </div>
  </div>
</section>

    </>
  )
}

export default Property