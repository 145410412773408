import React, { useState } from "react";

const LookingFor = () => {
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const moreClick = () => {
    setVisible((show) => !show);
  };
  const moreClick2 = () => {
    setVisible2((show) => !show);
  };
  const moreClick3 = () => {
    setVisible3((show) => !show);
  };
  return (
    <div>
      <section className="w3l-grids-3" id="about">
        <div className="container py-md-5 py-3">
          <div className="row bottom-ab-grids align-items-center">
            <div className="col-lg-6 bottom-ab-left">
              <h6 className="sub-titlehny" />
              {/* <h3 className="hny-title">WHAT ARE YOU LOOKING FOR?</h3> */}
              <h3 className="hny-title">We Specialize In</h3>

            </div>
            {/* <div className="col-lg-6 bottom-ab-right mt-lg-0 mt-3 pl-lg-4">
        &lt;%-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur hic odio
          voluptatem tenetur consequatur.</p>
        <p className="mt-3"> Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae;
          Nulla mollis dapibus nunc, ut rhoncus
          turpis sodales quis. Integer sit amet mattis quam.</p>--%&gt;
      </div> */}
          </div>
          <div className="row bottom_grids text-left">
           
            <div className="col-lg-4 col-md-6 mt-5">
              <div className="grid-block lookingfor">
                <a
                  href="#"
                  className={
                    visible2 ? "boxclass " : "fixed-box d-block p-lg-4 p-3"
                  }
                >
                  <span className="fa fa-building-o" aria-hidden="true" />
                  <h4 className="my-3">WAREHOUSE</h4>
                  <p className="">
                  Every warehouse requirement is unique and it depends solely on the business type.  Having the right warehouse in the right location can make a huge difference to your business. Our team at Guwahati Warehouses takes the time to understand your needs and requirement from all perspectives. This allows us to give you a property that is tailor-made for you and is the best option for your business. We have an elaborate inventory that includes, ready-to-move-in, under-construction, and BTS (built-to-suit). Contact Guwahati Warehouses today to find the best warehouses in Guwahati
                  </p>
                </a>
                <div
                  className="row center mt-2"
                  style={{ justifyContent: "center" }}
                >
                  {!visible2 ? (
                    <>
                      <button
                        className="btn btn-primary no-hover"
                        onClick={() => moreClick2()}
                      >
                        Read More
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="btn btn-primary no-hover"
                        onClick={() => moreClick2()}
                      >
                        Read less
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-5">
              <div className="grid-block lookingfor">
                <a
                  href="#"
                  className={
                    visible3
                      ? "boxclass box-trans"
                      : "fixed-box d-block p-lg-4 p-3 box-trans"
                  }
                >
                  <span className="fa fa-podcast" aria-hidden="true" />
                  <h4 className="my-3">INDUSTRIAL SHEDS </h4>
                  <p className="">
                  An industrial shed is an important part of any industrial and logistical zones of an area. Industrial sheds are used for a variety of things. The uses can include motor car repair, packaging, and small cottage industries. Industrial sheds are of varied kinds and we can offer you according to your preference.  The industrial shed options that we have with Guwahati Warehouses are the best and we can offer you a whole plethora of selections.  Do connect with Guwahati Warehouses and take your business a step ahead.
                  </p>
                </a>

                <div
                  className="row center mt-2"
                  style={{ justifyContent: "center" }}
                >
                  {!visible3 ? (
                    <>
                      <button
                        className="btn btn-primary no-hover"
                        onClick={() => moreClick3()}
                      >
                        Read More
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="btn btn-primary no-hover"
                        onClick={() => moreClick3()}
                      >
                        Read less
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mt-5">
              <div className="grid-block lookingfor">
                <a
                  href="#"
                  className={
                    visible ? "boxclass" : "fixed-box d-block p-lg-4 p-3"
                  }
                >
                  <span className="fa fa-cubes" aria-hidden="true" />
                  <h4 className="my-3">LAND</h4>
                  <p className>
                  We, at Guwahati Warehouses, list the best and most sort-after verified land parcels in and around Guwahati. We know that buying land involves a lot of processes and can hinder a smooth transaction. This could involve inconveniences like multiple owners, or proper paperwork. Working with Guwahati Warehouses will allow you to have only one point of contact.  We will ensure that you have a more organized interaction with the owners. Secondly, our in-house legal team will provide you with all the required assistance.
                  </p>
                </a>
                <div
                  className="row center mt-2"
                  style={{ justifyContent: "center" }}
                >
                  {!visible ? (
                    <>
                      <button
                        className="btn btn-primary no-hover"
                        onClick={() => moreClick()}
                      >
                        Read More
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="btn btn-primary no-hover"
                        onClick={() => moreClick()}
                      >
                        Read less
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-6 mt-5">
        <div className="grid-block">
          <a href="#" className="d-block p-lg-4 p-3">
            <span className="fa fa-money" aria-hidden="true" />
            <h4 className="my-3">COMMERCIAL</h4>
            <p className>Get details of all the commercial property for sale in prime location, office space, shops, showrooms etc.</p>
          </a>
        </div>
      </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default LookingFor;
