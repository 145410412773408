import React from "react";

const AboutUs = () => {
  return (
    <>
      <div className="w3l-cwp4-sec py-5">
        <div className="container py-md-5 py-3">
          <div className="cwp4-two row align-items-center">
            <div className="cwp4-text col-lg-6 aboutmodify">
              <h6 className="sub-titlehny">About Us</h6>

              <p>
             <b>Guwahati Warehouses</b> is the warehousing vertical of the Sivin Group and is led by Mr. Vikas Goenka.
              </p>
              <p>
              Mr. Goenka has around 20 years of experience of which he has worked in the real estate sector for more than 10 years. His guidance and experience are a huge asset to Guwahati Warehouses. We can assure you that if you are looking for industrial land or a warehouse, in and around Guwahati, Guwahati Warehouses is your best option.
              </p>
              <ul className="cont-4 mt-lg-5 ">
                <li>HOW DO WE HELP?
                </li>
              
              
               <li>
                  <span className="fa fa-check-circle-o" /> We help clients with 360-degree warehousing solutions in Guwahati so you have the ease of doing business.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" /> We list the highest number of warehouses in Guwahati and its surrounding.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" /> We help landlords connect with all major clients looking to set up an industry here in Guwahati.
                </li>
               
                <li>
                  <span className="fa fa-check-circle-o" />
                  We assist our clients throughout the deal, right from scouting properties to the deal closure.
                </li>
                <li>
                  <span className="fa fa-check-circle-o" />
                  Our in-house lawyer makes the legalities of the deal smoother and hassle-free.
                </li>
              </ul>
            </div>
            <div className="cwp4-image col-lg-6 pl-lg-5 mt-lg-0 mt-5">
              <img
                src="assets/images/VikasGoenka.jpg"
                className="img-fluid"
                alt
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
