import React from 'react'
import { Link } from 'react-router-dom'

const Ciril = () => {
  return (
    <>
      <div className="inner-banner"></div>
      <section className="w3l-breadcrumb">
        <div className="container">
          <ul className="breadcrumbs-custom-path">
            <li>
              <Link to="/">Home</Link>
              {/* <a href="#url">Home</a> */}
            </li>
            <li className="active">
              <span className="fa fa-arrow-right mx-2" aria-hidden="true" />{" "}
              CIRIL
            </li>
          </ul>
        </div>
      </section>

      <div className="w3l-cwp4-sec py-5">
        <div className="container py-md-5 py-3">
          <div className="cwp4-two row align-items-center">
            <div className="cwp4-image col-lg-6 pr-lg-5 mb-lg-0 mb-5">
             <Link to="https://www.ciril.in/"> <img src="assets/images/ciril.jpeg" className="img-fluid" alt /></Link>
            </div>
            <div className="cwp4-text col-lg-6 aboutpage">
              <h6 className="sub-titlehny"></h6>
              <h3 className="hny-title"> About CIRIL: </h3>
              <p className="black-color">
              CIRIL is India’s leading owner-operated commercial real estate brokerage company. Our member offices are leaders in their local markets and work in unison to provide clients with exceptional solutions for their real estate requirements. CIRIL is an acronym for the services its members offer to our clients i.e., Commercial / Office Services, Investment Services, Retail Services, Industrial & Logistics Services and Land Services. 
              </p>
              <p className="black-color">
              CIRIL was formed by some prominent real estate consultants who have been successfully running their real estate consulting businesses in their respective cities. Clients have begun to look at working with players who have a national uniform footprint. CIRIL was formed to create a unique platform for its members to serve clients under similar brands, systems, formats, and processes. 
              </p>
            </div>
            <div className="container mt-2 cwp4-text  py-md-5 py-3">
              <p className="black-color">
              CIRIL ensures that the members bought on board have extensive experience and exposure to the various service lines. To this extent, the initial group which formed CIRIL has an average experience of 1-2 decades in their respective markets. It took a lot of time to align different member processes and brands into a uniform system for our client’s benefit. 
              </p>
              <p className="black-color">
              Today CIRIL members work seamlessly as branches with information and requirements being shared across the platform smoothly. We have always had a strong IT background. The internal CRM track leads and evaluates our members' performance. The entire process of CIRIL has been defined only keeping its clients in mind. CIRIL members initially operated from all major cities across the country but now we are spreading to more cities. This offers our clients larger geographical options under the same umbrella. 
              </p>
              <p className="black-color">
              In order to ensure that each vertical performs well, CIRIL has a very structured and organized hierarchy.   Each vertical has a national head 
and a team spread across all its members. We now have 5 teams focusing on all the services we offer. These teams do business development together and ensure the delivery of assignments to the best of the local and national capabilities. 
<br></br>
CIRIL is headed by a professional board of directors at the national level. It also has a membership board with each member represented in this group. As on March 2023, we are a team of more than 500 people with a presence in 27 cities. Together we have closed more than 1.6 million Sqft of space.
              </p>
              <ul className="cont-4 mt-lg-5 mt-4">
                <li>
                  <span className="fa fa-check-circle-o" /> CIRIL Services:
                  <ul>
                    <li >Commercial Services</li>
                    <li >Investment Services</li>
                    <li >Retail Services</li>
                    <li >Industrial & Warehousing Services</li>
                    <li >Land Services</li>
                  </ul>
                </li>
               <li>
               <span className="fa fa-check-circle-o" />Our Presence: 
               <p>Ahmedabad. Bangalore. Bhubaneshwar. Chennai. Coimbatore. Delhi. Gurgaon. Guwahati. Hyderabad. Indore. Jaipur. Kolkata. Kochi. Mumbai. Noida. Nagpur. Navi Mumbai. Nashik. Pune. Ranchi. Rajkot. Surat. Siliguri. Vadodara. </p>
               </li>

               <li>
               <span className="fa fa-check-circle-o" />Email: info@ciril.in
             
               </li>

               <li>
               <span className="fa fa-check-circle-o" />  Website:  <Link to="https://www.ciril.in/"> WWW.ciril.in </Link>
              
               </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
     
    </>
  )
}

export default Ciril