import React from "react";
import { Link } from "react-router-dom";

const Cookies = () => {
  return (
    <>
      <div className="inner-banner"></div>
      <section className="w3l-breadcrumb">
        <div className="container">
          <ul className="breadcrumbs-custom-path">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li className="active">
              <span className="fa fa-arrow-right mx-2" aria-hidden="true" />
              Cookie Policy
            </li>
          </ul>
        </div>
      </section>

      <div className="w3l-cwp4-sec py-5">
        <div className="container py-md-5 py-3">
          <div className="cwp4-two row align-items-center">
            <div className="cwp4-text col-lg-12">
              <h6 className="sub-titlehny" />
              <h3 className="hny-title">Cookie Policy</h3>
              <p className="black-color">
                This is the Cookie Policy for Guwahati Warehouses, accessible
                from http://guwahatiwarehouses.com/ <br />
                What Are Cookies
                <br />
                As is common practice with almost all professional websites this
                site uses cookies, which are tiny files that are downloaded to
                your computer, to improve your experience. This page describes
                what information they gather, how we use it and why we sometimes
                need to store these cookies. We will also share how you can
                prevent these cookies from being stored however this may
                downgrade or 'break' certain elements of the sites
                functionality.
                <br />
                For more general information on cookies see the Wikipedia
                article on HTTP Cookies.
                <br />
                How We Use Cookies
                <br />
                We use cookies for a variety of reasons detailed below.
                Unfortunately in most cases there are no industry standard
                options for disabling cookies without completely disabling the
                functionality and features they add to this site. It is
                recommended that you leave on all cookies if you are not sure
                whether you need them or not in case they are used to provide a
                service that you use.
                <br />
                Disabling Cookies
                <br />
                You can prevent the setting of cookies by adjusting the settings
                on your browser (see your browser Help for how to do this). Be
                aware that disabling cookies will affect the functionality of
                this and many other websites that you visit. Disabling cookies
                will usually result in also disabling certain functionality and
                features of the this site. Therefore it is recommended that you
                do not disable cookies.
                <br />
                The Cookies We Set
                <br />
                Forms related cookies
                <br />
                When you submit data to through a form such as those found on
                contact pages or comment forms cookies may be set to remember
                your user details for future correspondence.
                <br />
                Site preferences cookies
                <br />
                In order to provide you with a great experience on this site we
                provide the functionality to set your preferences for how this
                site runs when you use it. In order to remember your preferences
                we need to set cookies so that this information can be called
                whenever you interact with a page is affected by your
                preferences.
                <br />
                Third Party Cookies
                <br />
                In some special cases we also use cookies provided by trusted
                third parties. The following section details which third party
                cookies you might encounter through this site.
                <br />
                This site uses Google Analytics which is one of the most
                widespread and trusted analytics solution on the web for helping
                us to understand how you use the site and ways that we can
                improve your experience. These cookies may track things such as
                how long you spend on the site and the pages that you visit so
                we can continue to produce engaging content.
                <br />
                For more information on Google Analytics cookies, see the
                official Google Analytics page.
                <br />
                From time to time we test new features and make subtle changes
                to the way that the site is delivered. When we are still testing
                new features these cookies may be used to ensure that you
                receive a consistent experience whilst on the site whilst
                ensuring we understand which optimisations our users appreciate
                the most.
                <br />
                As we sell products it's important for us to understand
                statistics about how many of the visitors to our site actually
                make a purchase and as such this is the kind of data that these
                cookies will track. This is important to you as it means that we
                can accurately make business predictions that allow us to
                monitor our advertising and product costs to ensure the best
                possible price.
                <br />
                The Google AdSense service we use to serve advertising uses a
                DoubleClick cookie to serve more relevant ads across the web and
                limit the number of times that a given ad is shown to you.
                <br />
                For more information on Google AdSense see the official Google
                AdSense privacy FAQ.
                <br />
                We also use social media buttons and/or plugins on this site
                that allow you to connect with your social network in various
                ways. For these to work the following social media sites
                including; {"{"}List the social networks whose features you have
                integrated with your site?:12{"}"}, will set cookies through our
                site which may be used to enhance your profile on their site or
                contribute to the data they hold for various purposes outlined
                in their respective privacy policies.
                <br />
                More Information
                <br />
                Hopefully that has clarified things for you and as was
                previously mentioned if there is something that you aren't sure
                whether you need or not it's usually safer to leave cookies
                enabled in case it does interact with one of the features you
                use on our site. This Cookies Policy was created with the help
                of the Cookies Policy Template Generator and the WebTerms
                Generator.
                <br />
                However if you are still looking for more information then you
                can contact us through one of our preferred contact methods:
                <br />
                Email: ea.vikasgoenka@sidvingroup.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cookies;
