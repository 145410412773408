import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
const Demo = () => {
  return (
    <>
<div className="inner-banner">
</div>

<section className="w3l-breadcrumb">
  <div className="container">
    <ul className="breadcrumbs-custom-path">
      <li><a href="/">Home</a></li>
      <li className="active"><span className="fa fa-arrow-right mx-2" aria-hidden="true" />Submit Property</li>
    </ul>
  </div>
</section>

<section className="w3l-contact-11 py-5">
  <div className="contacts-main py-lg-5 py-md-4">
    <div style={{margin: '8px auto', display: 'block', textAlign: 'center'}}>
    </div>
    <div className="title-content text-center">
      <h6 className="sub-titlehny">Submit Property Details</h6>
      <h3 className="hny-title"></h3>
    </div>
    <div style={{margin: '8px auto', display: 'block', textAlign: 'center'}}>
      {/*-728x90-*/}
    </div>
    <div className="form-41-mian section-gap mt-5">
      <div className="container">
        <div className=" align-form-map">
          <div className="form-inner-cont">
            <form  method="post" className="signin-form">
<div className='row'>
<div className='col-md-6 col-lg-6 col-sm-12'>
<div className="form-input">
                <label htmlFor="w3lName">Owner Name</label>
                <input type="text" name="w3lName" id="w3lName" placeholder required />
              </div>
</div>
<div className='col-md-6 col-lg-6 col-sm-12'>
<div className="form-input">
                <label htmlFor="w3lSender">Email</label>
                <input type="email" name="w3lSender" id="w3lSender" placeholder required />
              </div>
</div>
</div>

<div className='row'>
<div className='col-md-6 col-lg-6 col-sm-12'>
<div className="form-input">
                <label htmlFor="w3lName">Contact Number</label>
                <input type="phone" maxLength="10"  name="w3lName" id="w3lName" placeholder />
              </div>
</div>
<div className='col-md-6 col-lg-6 col-sm-12'>
<div className="form-input">
                <label htmlFor="w3lSender">City</label>
                <input type="text" name="w3lSender" id="w3lSender" placeholder required />
              </div>
</div>
</div>
<div className='row'>
<div className='col-md-6 col-lg-6 col-sm-12'>
<div className="form-input">
                <label htmlFor="w3lName">Property Address</label>
                <input type="text" name="w3lName" id="w3lName" placeholder />
              </div>
</div>
<div className='col-md-6 col-lg-6 col-sm-12'>
<div className="form-input">
                <label htmlFor="w3lSender">Pincode</label>
                <input type="text" name="w3lSender" id="w3lSender" placeholder required />
              </div>
</div>
</div>
             
             
              <div className="form-input">
                <label htmlFor="w3lMessage">Property Discription</label>
                <textarea placeholder name="w3lMessage" id="w3lMessage" required defaultValue={""} />
              </div>
              <div className="form-submit text-center">
                <button type="submit" className="btn btn-style btn-primary">Submit Message</button>
              </div>
            </form>
          </div>
       
        </div>
      </div>
    </div>
   
  </div></section>

    
    </>
  )
}

export default Demo