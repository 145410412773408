import React, { useState } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <section class="w3l-footer-29-main">
        <div class="footer-29 py-5">
          <div class="container py-lg-4">
            <div class="row footer-top-29">
              <div class="footer-list-29 col-lg-6">
                <h6 class="footer-title-29">About Company</h6>
                <p>
                 <span style={{fontWeight:"bold" ,color:"var(--primary)"}}>Guwahati Warehouses</span >  is a part of Sidvin Group and is the <span style={{fontWeight:"bold"}}>‘Land
                  & Warehousing’</span>  vertical of the company. Our scope includes all
                  kinds of warehouses, be they ready to move in, under
                  construction, or BTS(Built-To-Suit). We can also help you
                  acquire land for setting up industries, factories, and
                  institutions.
                </p>
                <div class="main-social-footer-29 mt-4">
                  <a
                    href="https://www.facebook.com/sidvinrealtynetwork"
                    class="facebook"
                  >
                    <span class="fa fa-facebook"></span>
                  </a>
                  <a href="/" class="twitter">
                    <span class="fa fa-twitter"></span>
                  </a>

                  
                  <a
                    href="/"
                    class="instagram"
                  >
                    <span class="fa fa-google-plus"></span>
                  </a>
                  <a
                    href="https://www.linkedin.com/in/vikasgoenkaofficial/"
                    class="linkedin"
                  >
                    <span class="fa fa-linkedin"></span>
                  </a>
                </div>
              </div>

              <div class="col-lg-2 col-md-6 col-sm-4 footer-list-29 footer-2 mt-lg-0 mt-5">
                <ul>
                  <h6 class="footer-title-29">Useful Links</h6>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>

                  <li>
                    <Link to="/contact">Contact us</Link>
                  </li>
                  <li>
                    <Link to="/termcondition">Terms and Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy">Privacy</Link>
                  </li>
                  <li>
                    <Link to="/cookies">Cookie Policy</Link>
                  </li>
                </ul>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-8 footer-list-29 footer-1 mt-lg-0 mt-5">
                <h6 class="footer-title-29">Contact Us</h6>
                <ul>
                  <li>
                    <p>
                      <span class="fa fa-map-marker"></span> Guwahati Warehouses 
(A unit of Sidvin Group)
206, 2nd Floor 
Shankar Complex  
Christian Basti 
GS Road 
Guwahati-781005
                    </p>
                  </li>
                  <li>
                    <a href="tel: +91-7788823888">
                      <span class="fa fa-phone"></span> +91-7788823888
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:  info@guwahatiwarehouses.com"
                      class="mail"
                    >
                      <span class="fa fa-envelope-open-o"></span>
                       info@guwahatiwarehouses.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="w3l-footer-29-main w3l-copyright">
        <div class="container">
          <div class="bottom-copies">
            <p class="copy-footer-29 text-center">
              © 2023 Guwahati Warehouses. All rights reserved. Design by{" "}
              <a href="https://www.dexterous.in/" target="_blank">
                Dexterous Technologies
              </a>
            </p>
          </div>
        </div>

        <button id="movetop" onClick={scrollToTop} title="Go to top">
          <span class="fa fa-angle-up"></span>
        </button>
      </section>
    </>
  );
};

export default Footer;
